import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Styles/SliderCategory.css";
function CategorySilder({ PP }) {
  return (
    <Fragment>
      <div className="mb-4 pb-4" />
      <Card className="custom-card">
        <Link to={`/Category/${PP.id}`}>
          <Card.Img
            // variant="top"
            className="py-3 card-img"
            src={PP.image_url}
            alt={PP.name}
          />

          <h5>{PP.name}</h5>
        </Link>
        <Card.Body className="text-center"></Card.Body>
      </Card>
    </Fragment>
  );
}

export default CategorySilder;
