import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CategorySilder from "./CategorySilder";

const SliderCategory = () => {
  const [category, setCategory] = useState([]);
  const { i18n } = useTranslation();

  const getApiProducts = async () => {
    try {
      const res = await fetch(`https://menu-api.webappdevs.com/api/categories`);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      setCategory(
        data.map((item) => {
          const nameTranslations = JSON.parse(item.name);
          const currentLanguage = i18n.language || "en";
          const translatedName = nameTranslations[currentLanguage];
          const imageUrl = item.image_url
            ? `https://menu-api.webappdevs.com/storage/${item.image_url}`
            : null;
          return {
            key: item.id,
            id: item.id,
            name: translatedName,
            image_url: imageUrl,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getApiProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <Container>
      <Slider {...settings}>
        {category.map((p) => (
          <div key={p.id}>
            <CategorySilder PP={p} />
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default SliderCategory;
